<template>
  <section>
    <div class="d-flex align-center mb-6">
      <div class="text-h5 font-weight-bold mr-4">
        <span class="saira">Regulamento</span>
      </div>
    </div>

    <v-card v-if="!!selected.rulesUrl" color="surface" class="pa-8" flat tile>
      <div class="d-flex justify-end mb-6">
        <PrimaryButton
          icon="mdi-link"
          text="Abrir em nova aba"
          target="_blank"
          @click="openRules()"
        />
      </div>

      <iframe
        v-if="selected.rulesUrl"
        :src="`${selected.rulesUrl}/preview?embedded=true`"
        style="border: none"
        width="100%"
        height="600px"
      />
    </v-card>

    <div v-else class="d-flex justify-center align-center">
      <span class="text-body-1 subtext--text">
        Nenhum resultado foi encontrado
      </span>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";
import PrimaryButton from "@/components/buttons/PrimaryButton.vue";

export default {
  components: {
    PrimaryButton,
  },

  computed: {
    ...mapState(["selected"]),
  },

  methods: {
    openRules() {
      if (!this.selected.rulesUrl) return;

      window.open(this.selected.rulesUrl, "_blank").focus();
    },
  },
};
</script>

<style></style>
